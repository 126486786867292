import React, { useState, useEffect, useRef } from 'react'
import {
    HttpError,
    IResourceComponentsProps,
    useNavigation,
    useModal,
    useTranslate,
    useApiUrl,
    useNotification,
    useShow
} from "@refinedev/core";
import { useForm, useModalForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { List, Edit, NumberField, useAutocomplete, EditButton, ShowButton, DeleteButton } from "@refinedev/mui";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import Avatar from "@mui/material/Avatar";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { Autocomplete } from "@mui/lab";
import { DataGrid, GridColDef, GridExpandMoreIcon } from "@mui/x-data-grid";
import { CreateProductGroup } from 'components/productGroups/create';
import { EditProductGroup } from 'components/productGroups/edit';
import { CreateSupplementGroup } from 'components/supplementGroups/create';
import { EditSupplementGroup } from 'components/supplementGroups/edit';
import { CreateProduct } from 'components/product';
import { EditProduct } from 'components/product/edit';
import { Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import { CreateSupplement } from 'components/supplement/create';
import axios from "axios";
import { EditSupplement } from 'components/supplement/edit';
import { socket } from "App";
import newOrderSound from '../../sound/new_order_notif.mp3';

const orderCreatedSound = new Audio(newOrderSound);

 
export const MenuEdit: React.FC<IResourceComponentsProps> = () => {
    const notif = useNotification();

    useEffect(() => {
    socket.on('groupOrderCreated', (newOrder) => {
        // Check if the order is not a POS order
        if (!newOrder.isPOS) {
            if (notif.open) {
                notif.open({
                    message: 'New order received',
                    type: 'success',
                });
            }
            orderCreatedSound.play();
        }
    });

    socket.on('groupOrderUpdated', (updatedOrder) => {
        // Check if the order is not a POS order
        if (!updatedOrder.isPOS) {
            if (notif.open) {
                notif.open({
                    message: 'Order updated',
                    description: `Order ${updatedOrder.groupOrderNumber} has been updated.`,
                    type: 'success',
                });
            }
        }
    });

    socket.on('groupOrderDeleted', (deletedOrder) => {
        // Check if the order is not a POS order
        if (!deletedOrder.isPOS) {
            if (notif.open) {
                notif.open({
                    message: 'Order deleted',
                    description: `Order ${deletedOrder.groupOrderNumber} has been deleted.`,
                    type: 'progress',
                });
            }
        }
    });

    return () => {
        socket.off('groupOrderCreated');
        socket.off('groupOrderUpdated');
        socket.off('groupOrderDeleted');
    };
}, []);

    const t = useTranslate();
    const { edit } = useNavigation();
    const {
        register,
        control,
        refineCore: { formLoading, queryResult  },
        formState: { errors },
        saveButtonProps,
        setValue
    } = useForm();
    const { show: showProdGroup, visible: visibleProdGroup, close: closeProdGroup } = useModal();
    const { show: showSuppGroup, visible: visibleSuppGroup, close: closeSuppGroup } = useModal();
    const { show: showProduct, visible: visibleProduct, close: closeProduct } = useModal();
    const { show: showSupplement, visible: visibleSupplement, close: closeSupplement } = useModal();
    
    const { show: showProdGroupEdit, visible: visibleProdGroupEdit, close: closeProdGroupEdit } = useModal();
    const { show: showSuppGroupEdit, visible: visibleSuppGroupEdit, close: closeSuppGroupEdit } = useModal();
    const { show: showProductEdit, visible: visibleProductEdit, close: closeProductEdit } = useModal();
    const { show: showSupplementEdit, visible: visibleSupplementEdit, close: closeSupplementEdit } = useModal();
    // const { setShowId } = useShow();
    
    const menusData = queryResult?.data?.data;
    const [menuId, setMenuId] = useState(menusData?._id)
    const [selectedProductGroup, setSelectedProductGroup] = useState({_id: '', name: '', description: '', products: []})
    const [selectedSupplementGroup, setSelectedSupplementGroup] = useState({_id: '', name: '', description: '', maxSupplements: 0, supplements: []})
    const [selectedProduct, setSelectedProduct] = useState({_id: '', name: '', description: ''})
    const [selectedSupplement, setSelectedSupplement] = useState({_id: '', name: '', description: ''})
    const [coverImage, setcoverImage] = useState("")
    const apiUrl = useApiUrl();
    // Let's assume you get the menu ID from the `menusData`
    // Using useEffect to set the menuId when menusData is available or changes
    
    const { autocompleteProps } = useAutocomplete({
        resource: "restaurantBrands",
        // defaultValue: menusData?.restaurantBrand,
    });
    
    
    useEffect(() => {
        if (menusData?._id && !menuId) {
            setMenuId(menusData._id);
        }
        setcoverImage(menusData?.coverImage);
    }, [menusData]);

 

    const uploadImageHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files && event.target.files.length > 0) {
    
            const file = event.target.files[0];
        
            if (file) {
                const formData = new FormData();
                formData.append('coverImage', file); // 'coverImage' is the field name expected by your server
        
                try {
                    const response = await axios.post(apiUrl + "/products/coverImage", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                
                    // Assuming the server returns the file path in the response
                    const imagePath = response.data.path; // Adjust this depending on the response structure
                
                    // Set the value in the form
                    setValue('coverImage', imagePath); 
                    setcoverImage(imagePath);
                } catch (error) {
                    console.error('Error uploading file:', error);
                    // Handle the error, e.g. show an error message
                }
            }
        }
    };
    const fileInputRef = useRef<HTMLInputElement>(null);

    const onAvatarClick = () => {
        if(fileInputRef && fileInputRef?.current) {
            fileInputRef.current.click();
        }
    };
    
    
    const createDrawerFormProps = useModalForm<
    HttpError>
({
  refineCoreProps: {
      action: "create",
      resource: "productGroups",
      redirect: false, // Avoid default redirection
      onMutationSuccess(data) { // Use the event callback for successful mutation
        // Assuming 'data' includes the new `id` after creation
        const menuId = data?.data?.menu; // Make sure to reference correct id property
        if (menuId) {
          queryResult?.refetch();
          closeProdGroup();
        }
      }
    },
});
    const createProdDrawerFormProps = useModalForm<
    HttpError>
({
  refineCoreProps: {
      action: "create",
      resource: "products",
      redirect: false, // Avoid default redirection
      onMutationSuccess(data) { // Use the event callback for successful mutation
        // Assuming 'data' includes the new `id` after creation
        const menuId = data?.data?.menu; // Make sure to reference correct id property
        if (menuId) {
          queryResult?.refetch();
          closeProduct();
        }
      }
    },
});
  const createSuppDrawerFormProps = useModalForm<
      HttpError>
  ({
    refineCoreProps: {
        action: "create",
        resource: "supplementGroups",
        redirect: false, // Avoid default redirection
        onMutationSuccess(data) { // Use the event callback for successful mutation
            // Assuming 'data' includes the new `id` after creation
            queryResult?.refetch();
            closeSuppGroup();
        }
      },
  });
  const createSupplementDrawerFormProps = useModalForm<
      HttpError>
  ({
    refineCoreProps: {
        action: "create",
        resource: "supplements",
        redirect: false, // Avoid default redirection
        onMutationSuccess(data) { // Use the event callback for successful mutation
            // Assuming 'data' includes the new `id` after creation
            queryResult?.refetch();
            closeSuppGroup();
        }
      },
  });
    const editDrawerFormProps = useModalForm<
      HttpError>
  ({
    refineCoreProps: {
        action: "edit",
        resource: "productGroups",
        redirect: false, // Avoid default redirection
        id: selectedProductGroup?._id,
        onMutationSuccess(data) { // Use the event callback for successful mutation
          // Assuming 'data' includes the new `id` after creation
          const menuId = data?.data?.menu; // Make sure to reference correct id property
          if (menuId) {
            queryResult?.refetch();
            closeProdGroup();
          }
        }
      },
  });
      const editProdDrawerFormProps = useModalForm<
      HttpError>
  ({
    refineCoreProps: {
        action: "edit",
        resource: "products",
        redirect: false, // Avoid default redirection
        id: selectedProduct?._id,
        onMutationSuccess(data) { // Use the event callback for successful mutation
          // Assuming 'data' includes the new `id` after creation
            queryResult?.refetch();
            closeProduct();
        }
      },
  });
      const editSuppDrawerFormProps = useModalForm<
      HttpError>
  ({
    refineCoreProps: {
        action: "edit",
        resource: "supplementGroups",
        redirect: false, // Avoid default redirection
        id: selectedSupplementGroup?._id,
        onMutationSuccess(data) { // Use the event callback for successful mutation
            // Assuming 'data' includes the new `id` after creation
            queryResult?.refetch();
            closeSuppGroup();
        }
      },
  });
      

    const prodGroupsColumns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "name",
                headerName: t("productGroups.fields.name"),
                width: 300,
            },
            {
                field: "description",
                headerName: t("productGroups.fields.description"),
                width: 300,
            },
            {
                field: "actions",
                headerName: t("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            {/* <EditButton key={1} hideText onClick={() => edit("productGroups", row._id)} /> */}
                            <EditButton
                                onClick={() => {
                                    setSelectedProductGroup(row);
                                    showProdGroupEdit();
                                    // setShowId(row._id);
                                }}
                                hideText
                                key={2}
                            />
                            <ShowButton key={3}  hideText recordItemId={row._id} />
                            <DeleteButton key={4} hideText recordItemId={row._id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],  [t],
    )

    const suppGroupsColumns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "name",
                headerName: t("supplementGroups.fields.name"),
                width: 300,
            },
            {
                field: "description",
                headerName: t("supplementGroups.fields.description"),
                width: 300,
            },
            {
                field: "maxSupplements",
                headerName: t("supplementGroups.fields.maxSupplements"),
                width: 300,
            },
            {
                field: "actions",
                headerName: t("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText onClick={() => {
                                    setSelectedSupplementGroup(row);
                                    showSuppGroupEdit();
                                }} />
                            <ShowButton hideText recordItemId={row._id} />
                            <DeleteButton hideText recordItemId={row._id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],  [t],
    )
    const productColumns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "name",
                headerName: t("products.fields.name"),
                width: 300,
            },
            {
                field: "description",
                headerName: t("products.fields.description"),
                width: 300,
            },
            {
                field: "price",
                headerName: t("products.fields.price"),
                width: 300,
            },
            {
                field: "actions",
                headerName: t("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText onClick={() => {
                                    setSelectedProduct(row);
                                    showProductEdit();
                                }} />
                            <ShowButton hideText recordItemId={row._id} />
                            <DeleteButton hideText recordItemId={row._id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],  [t],
    )
  
   
    const supplementColumns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "name",
                headerName: t("supplements.fields.name"),
                width: 300,
            },
            {
                field: "description",
                headerName: t("supplements.fields.description"),
                width: 300,
            },
            {
                field: "isSupplement",
                headerName: t("supplements.fields.isSupplement"),
                width: 300,
            },
            {
                field: "actions",
                headerName: t("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText onClick={() => {
                                    setSelectedSupplement(row);
                                    console.log(row);
                                    showSupplementEdit();
                                }} />
                            <ShowButton hideText recordItemId={row._id} />
                            <DeleteButton hideText recordItemId={row._id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],  [t],
    )

    return (
        <>
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps} >
            <form>
                <Grid
                    container
                    marginTop="8px"
                    sx={{
                        marginX: { xs: "0px" },
                        paddingX: { xs: 1, md: 4 },
                    }}
                >
                    <FormControl sx={{ width: { md: "50%", xs: "100%"} }}>
                        <Stack
                            gap={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Avatar
                                src={apiUrl + "/" + coverImage}
                                alt="Menu image"
                                onClick={onAvatarClick}
                                sx={{
                                    width: {
                                        xs: 180,
                                        lg: 256,
                                    },
                                    height: {
                                        xs: 180,
                                        lg: 256,
                                    },
                                }}
                            />
                            <input
                                    {...register("coverImage")}
                                    accept="image/*"
                                    id="cover-image"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={uploadImageHandler}
                                    ref={fileInputRef}
                                />
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                }}
                            >
                                {t("menus.fields.coverImage")}
                            </Typography>
                        </Stack>
                    </FormControl>
                    <Grid item xs={12} md={4}>
                        <Stack gap="24px">
                            <FormControl>
                                <FormLabel
                                    required
                                    sx={{
                                        marginBottom: "8px",
                                        fontWeight: "700",
                                        fontSize: "14px",
                                        color: "text.primary",
                                    }}
                                >
                                    {t("menus.fields.name")}
                                </FormLabel>
                                <TextField
                                    {...register("name", {
                                        required: t("errors.required.field", {
                                            field: "name",
                                        }),
                                    })}
                                    size="small"
                                    margin="none"
                                    variant="outlined"
                                />
                                {errors.name && (
                                    <FormHelperText error>
                                        {errors.name.message as string}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl>
                                <FormLabel
                                    required
                                    sx={{
                                        marginBottom: "8px",
                                        fontWeight: "700",
                                        fontSize: "14px",
                                        color: "text.primary",
                                    }}
                                >
                                    {t("menus.fields.description")}
                                </FormLabel>
                                <TextField
                                    {...register("description", {
                                        required: t("errors.required.field", {
                                            field: "description",
                                        }),
                                    })}
                                    size="small"
                                    margin="none"
                                    variant="outlined"
                                />
                                {errors.description && (
                                    <FormHelperText error>
                                        {errors.description.message as string}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            {!autocompleteProps.loading &&
                            <FormControl>
                            <FormLabel
                                    required
                                    sx={{
                                        marginBottom: "8px",
                                        fontWeight: "700",
                                        fontSize: "14px",
                                        color: "text.primary",
                                    }}
                                >
                                    {t("menus.fields.restaurantBrand")}
                                </FormLabel>
                                <Controller
                                    control={control}
                                    name="restaurantBrand"
                                    rules={{
                                        required: t(
                                            "errors.required.field",
                                            { field: "RestaurantBrand" },
                                        ),
                                    }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            disablePortal
                                            {...autocompleteProps}
                                            {...field}
                                            defaultValue={menusData?.restaurantBrand}
                                            onChange={(_, value) => {
                                                
                                                field.onChange(value?._id ?? '');
                                            }}
                                            getOptionLabel={(item) => {
                                                return item?.name
                                                    ? item?.name
                                                    : "";
                                            }}
                                            isOptionEqualToValue={(
                                                option,
                                                value,
                                            ) =>
                                            {
                                                return value === undefined || option?._id === value?._id
                                            }
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // label={t(
                                                    //     "menus.fields.restaurantBrand",
                                                    // )}
                                                    variant="outlined"
                                                    error={
                                                        !!errors.restaurantBrand
                                                            ?.message
                                                    }
                                                    required
                                                />
                                            )}
                                        />
                                    )}
                                />
                                {errors.restaurantBrand && (
                                    <FormHelperText error>
                                        {errors.restaurantBrand.message  as string}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            }
                            
                                
                           
                        </Stack>
                    <FormControl fullWidth>
                        <FormLabel
                            required
                            sx={{
                                marginBottom: "8px",
                                fontWeight: "700",
                                fontSize: "14px",
                                color: "text.primary",
                            }}
                        >
                            {t("menus.fields.shippingPrice")}
                        </FormLabel>
                        <TextField
                            {...register("shippingPrice", {
                                required: t("errors.required.field", {
                                    field: "shippingPrice",
                                }),
                            })}
                            type="number"
                            margin="none"
                            required
                            fullWidth
                        />
                        {errors.shippingPrice && (
                            <FormHelperText error>
                                {errors.shippingPrice.message as string}
                            </FormHelperText>
                        )}
                    </FormControl>
                    </Grid>
                </Grid>
            </form>
        </Edit>
        <Accordion>
            <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Product groups</Typography>
        </AccordionSummary>
        <AccordionDetails>
<Stack gap={1} marginBottom={2} marginTop={2} >
{/* {menusData?.productGroups?.map((productGroup: any) => {
    return ( */}
        <List
            headerProps={{
                title: t("productGroups.productGroups"),
                style: {backgroundColor: "#f0f0f0 !important"}
            }}
            resource='productGroups'
            breadcrumb=''
            createButtonProps={{ 
                onClick: () => {
                    showProdGroup()
                }
            }}
        >
            
          
            <DataGrid
                autoHeight
                columns={prodGroupsColumns}
                rows={menusData?.productGroups || []}
                hideFooterPagination
                rowHeight={124}
                getRowId={row => row._id}
            />
        </List>
    {/* )})} */}
</Stack>
</AccordionDetails>
</Accordion>
<Accordion>
    <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Supplement groups</Typography>
        </AccordionSummary>
        <AccordionDetails>
<Stack gap={1} marginTop={2}>

        <List
            headerProps={{
                title: t("supplementGroups.supplementGroups"),
            }}
            resource='supplementGroups'
            breadcrumb=''
            createButtonProps={{ 
                onClick: () => {
                    showSuppGroup()
                }
            }}

        >
            <DataGrid
                autoHeight
                columns={suppGroupsColumns}
                rows={menusData?.supplementGroups || []}
                hideFooterPagination
                rowHeight={124}
                getRowId={row => row._id}
            />
        </List>
</Stack>
</AccordionDetails>
</Accordion>
<Accordion>
    <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Products</Typography>
        </AccordionSummary>
        <AccordionDetails>
<Stack gap={1} marginTop={2}>

        <List
            headerProps={{
                title: t("products.products"),
            }}
            resource='products'
            breadcrumb=''
            createButtonProps={{ 
                onClick: () => {
                    showProduct()
                }
            }}

        >
            <DataGrid
                autoHeight
                columns={productColumns}
                rows={menusData?.products || []}
                hideFooterPagination
                rowHeight={124}
                getRowId={row => row._id}
            />
        </List>
</Stack>
</AccordionDetails>
</Accordion>
<Accordion>
    <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Supplements</Typography>
        </AccordionSummary>
        <AccordionDetails>
<Stack gap={1} marginTop={2}>

        <List
            headerProps={{
                title: t("supplements.supplements"),
            }}
            resource='supplements'
            breadcrumb=''
            createButtonProps={{ 
                onClick: () => {
                    showSupplement()
                }
            }}

        >
            <DataGrid
                autoHeight
                columns={supplementColumns}
                rows={menusData?.supplements || []}
                hideFooterPagination
                rowHeight={124}
                getRowId={row => row._id}
            />
        </List>
</Stack>
</AccordionDetails>
</Accordion>
{/* {menusData && (
                <StoreProducts
                    record={menusData?.productGroups[0]?.products}
                    menu={menusData?._id}
                    close={close}
                    visible={visible}
                />
            )} */}

        
            {/* Product */}
            <CreateProduct
                {...createProdDrawerFormProps}
                menu={menuId}
                close={closeProduct}
                visible={visibleProduct}
            />
             <EditProduct 
                {...editProdDrawerFormProps}
                // allProducts={menusData?.products}
                menu={menuId}
                close={closeProductEdit}
                visible={visibleProductEdit}
                productData={selectedProduct}
            />
            {/* Supplement */}
            <CreateSupplement
                {...createSupplementDrawerFormProps}
                menu={menuId}
                close={closeSupplement}
                visible={visibleSupplement}
            />
            <EditSupplement
                {...createSupplementDrawerFormProps}
                menu={menuId}
                close={closeSupplementEdit}
                visible={visibleSupplementEdit}
                supplementData={selectedSupplement}
            />
           
           {/* Supplement group */}
            <CreateSupplementGroup 
                {...createSuppDrawerFormProps}
                allSupplements={menusData?.supplements}
                menu={menuId}
                close={closeSuppGroup}
                visible={visibleSuppGroup}
            />
             <EditSupplementGroup 
                {...editSuppDrawerFormProps}
                allSupplements={menusData?.supplements}
                menu={menuId}
                close={closeSuppGroupEdit}
                visible={visibleSuppGroupEdit}
                supplementGroupData={selectedSupplementGroup}
            />
            {/* Product group */}
            <CreateProductGroup 
                {...editDrawerFormProps}
                allProducts={menusData?.products}
                menu={menuId}
                close={closeProdGroup}
                visible={visibleProdGroup}
            />
            <EditProductGroup 
                {...editDrawerFormProps}
                allProducts={menusData?.products}
                menu={menuId}
                close={closeProdGroupEdit}
                visible={visibleProdGroupEdit}
                productGroupData={selectedProductGroup}
            />
           
        
</>)};
