import React from "react";
import { useEffect } from "react";
import {
    useShow,
    IResourceComponentsProps,
    useTranslate,
    useMany,
    useNotification
} from "@refinedev/core";
import {
    Show,
    TextFieldComponent as TextField,
    NumberField,
    TagField,
    DateField,
    List,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Typography, Stack } from "@mui/material";
import { socket } from "App";
import newOrderSound from '../../sound/new_order_notif.mp3';

const orderCreatedSound = new Audio(newOrderSound);




export const MenuShow: React.FC<IResourceComponentsProps> = () => {
    const notif = useNotification();

    useEffect(() => {
        socket.on('groupOrderCreated', (newOrder) => {
            // Check if the order is not a POS order
            if (!newOrder.isPOS) {
                if (notif.open) {
                    notif.open({
                        message: 'New order received',
                        type: 'success',
                    });
                }
                orderCreatedSound.play();
            }
        });
    
        socket.on('groupOrderUpdated', (updatedOrder) => {
            // Check if the order is not a POS order
            if (!updatedOrder.isPOS) {
                if (notif.open) {
                    notif.open({
                        message: 'Order updated',
                        description: `Order ${updatedOrder.groupOrderNumber} has been updated.`,
                        type: 'success',
                    });
                }
            }
        });
    
        socket.on('groupOrderDeleted', (deletedOrder) => {
            // Check if the order is not a POS order
            if (!deletedOrder.isPOS) {
                if (notif.open) {
                    notif.open({
                        message: 'Order deleted',
                        description: `Order ${deletedOrder.groupOrderNumber} has been deleted.`,
                        type: 'progress',
                    });
                }
            }
        });
    
        return () => {
            socket.off('groupOrderCreated');
            socket.off('groupOrderUpdated');
            socket.off('groupOrderDeleted');
        };
    }, []);
    

    const translate = useTranslate();
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;
    console.log(record)

    // const { data: categoriesData, isLoading: categoriesIsLoading } = useMany({
    //     resource: "categories",
    //     ids: record?.categories || [],
    //     queryOptions: {
    //         enabled: !!record && !!record?.categories?.length,
    //     },
    // });

    // const { data: productGroupsData, isLoading: productGroupsIsLoading } =
    //     useMany({
    //         resource: "productGroups",
    //         ids: record?.productGroups || [],
    //         queryOptions: {
    //             enabled: !!record && !!record?.productGroups?.length,
    //         },
    //     });

    const suppGroupsColumns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "name",
                headerName: translate("supplements.fields.name"),
                width: 300,
            },
        ],  [translate],
        )

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    {translate("menus.fields.restaurantBrand")}
                </Typography>
                <TextField value={record?.restaurantBrand?.name} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("menus.fields.name")}
                </Typography>
                <TextField value={record?.name} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("menus.fields.description")}
                </Typography>
                <TextField value={record?.description} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("menus.fields.shippingPrice")}
                </Typography>
                <NumberField value={record?.shippingPrice ?? ""} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("menus.fields.coverImage")}
                </Typography>
                <TextField value={record?.coverImage} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("menus.fields.categories")}
                </Typography>
                    <>
                        {record?.categories?.length ? (
                            <Stack direction="row" spacing={1}>
                                {record?.categories?.map(
                                    (categories: any) => (
                                        <TagField
                                            key={categories?.name}
                                            value={categories?.name}
                                        />
                                    ),
                                )}
                            </Stack>
                        ) : (
                            <></>
                        )}
                    </>
                <Typography variant="body1" fontWeight="bold">
                    {translate("menus.fields.productGroups")}
                </Typography>
                    {record?.productGroups?.length ? (
                        <Stack direction="row" spacing={1}>
                            {record?.productGroups?.map(
                                (productGroups: any) => (
                                    <TagField
                                        key={productGroups?.name}
                                        value={productGroups?.name}
                                    />
                                ),
                            )}
                        </Stack>
                    ) : (
                        <></>
                    )}
                <Typography variant="body1" fontWeight="bold">
                    {translate("menus.fields.supplements")}
                </Typography>
                <Stack direction="row" spacing={1}>
                    {record?.supplements?.map((item: any) => (
                        <TagField value={item} key={item} />
                    ))}
                </Stack>
                <Typography variant="body1" fontWeight="bold">
                    {translate("menus.fields.supplementGroups")}
                </Typography>
                <Stack gap={1}>
                    {record?.supplementGroups?.map((supplementGroup: any) => {
                        console.log(supplementGroup);
                        return (

                            <List
                                headerProps={{
                                    title: translate("supplementGroups.supplementGroups"),
                                }}
                            >
                                <DataGrid
                                    autoHeight
                                    columns={suppGroupsColumns}
                                    rows={supplementGroup?.supplements || []}
                                    hideFooterPagination
                                    rowHeight={124}
                                    getRowId={row => row._id}
                                    // components={{
                                    //     Footer: CustomFooter,
                                    // }}
                                />
                            </List>
                        )})}
                </Stack>
                <Typography variant="body1" fontWeight="bold">
                    {translate("menus.fields.discounts")}
                </Typography>
                <Stack direction="row" spacing={1}>
                    {record?.discounts?.map((item: any) => (
                        <TagField value={item?.name} key={item?.name} />
                    ))}
                </Stack>
                <Typography variant="body1" fontWeight="bold">
                    {translate("menus.fields.createdAt")}
                </Typography>
                <DateField value={record?.createdAt} />
                <Typography variant="body1" fontWeight="bold">
                    {translate("menus.fields.updatedAt")}
                </Typography>
                <DateField value={record?.updatedAt} />
            </Stack>
        </Show>
    );
};
