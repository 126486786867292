import React, { useEffect } from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    DeleteButton,
    List,
    EmailField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useTranslate, useNotification } from "@refinedev/core";
import { socket } from "App";
import newOrderSound from '../../sound/new_order_notif.mp3';

const orderCreatedSound = new Audio(newOrderSound);

export const UserList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { dataGridProps } = useDataGrid();

    const notif = useNotification();

    useEffect(() => {
        socket.on('groupOrderCreated', (newOrder) => {
            // Check if the order is not a POS order
            if (!newOrder.isPOS) {
                if (notif.open) {
                    notif.open({
                        message: 'New order received',
                        type: 'success',
                    });
                }
                orderCreatedSound.play();
            }
        });
    
        socket.on('groupOrderUpdated', (updatedOrder) => {
            // Check if the order is not a POS order
            if (!updatedOrder.isPOS) {
                if (notif.open) {
                    notif.open({
                        message: 'Order updated',
                        description: `Order ${updatedOrder.groupOrderNumber} has been updated.`,
                        type: 'success',
                    });
                }
            }
        });
    
        socket.on('groupOrderDeleted', (deletedOrder) => {
            // Check if the order is not a POS order
            if (!deletedOrder.isPOS) {
                if (notif.open) {
                    notif.open({
                        message: 'Order deleted',
                        description: `Order ${deletedOrder.groupOrderNumber} has been deleted.`,
                        type: 'progress',
                    });
                }
            }
        });
    
        return () => {
            socket.off('groupOrderCreated');
            socket.off('groupOrderUpdated');
            socket.off('groupOrderDeleted');
        };
    }, []);
    

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "first_name",
                flex: 1,
                headerName: translate("users.fields.first_name"),
                minWidth: 200,
            },
            {
                field: "last_name",
                flex: 1,
                headerName: translate("users.fields.last_name"),
                minWidth: 200,
            },
            {
                field: "email",
                flex: 1,
                headerName: translate("users.fields.email"),
                minWidth: 250,
                renderCell: function render({ value }) {
                    return <EmailField value={value} />;
                },
            },
            {
                field: "phone_number",
                flex: 1,
                headerName: translate("users.fields.phone_number"),
                type: "number",
                minWidth: 200,
            },
            {
                field: "role",
                flex: 1,
                headerName: translate("users.fields.role"),
                minWidth: 200,
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row._id} />
                            <ShowButton hideText recordItemId={row._id} />
                            <DeleteButton hideText recordItemId={row._id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [translate],
    );

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight getRowId={row => row._id}/>
        </List>
    );
};
