import React, { useEffect, useState } from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    DeleteButton,
    List,
    TagField,
    DateField,
} from "@refinedev/mui";
import { format } from 'date-fns';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    IResourceComponentsProps,
    useTranslate,
    useMany,
    useList,
    useNotification,
} from "@refinedev/core";
import io from 'socket.io-client';
import { CustomTooltip } from "components/customTooltip";
import { Stack, Typography } from "@mui/material";
import { OrderStatus } from "components/orderStatus";
import { socket } from "App";
import newOrderSound from '../../sound/new_order_notif.mp3';

const orderCreatedSound = new Audio(newOrderSound);

export const OrderList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { dataGridProps } = useDataGrid({
        resource: 'groupedOrders',
        filters: {
          permanent: [
            {
              field: "isPOS",
              operator: "eq",
              value: false, // Permanent filter: Always exclude POS orders
            },
          ],
        },
        sorters: {
          initial: [
            {
              field: "createdAt",
              order: "desc", // Initial sort: Newest first
            },
          ],
          mode: 'server', // Enable server-side sorting
        },
        pagination: {
          mode: 'server', // Enable server-side pagination
        },
      });
      
    
    

    const notif = useNotification();

    useEffect(() => {
        socket.on('groupOrderCreated', (newOrder) => {
            // Check if the order is not a POS order
            if (!newOrder.isPOS) {
                if (notif.open) {
                    notif.open({
                        message: 'New order received',
                        type: 'success',
                    });
                }
                orderCreatedSound.play();
            }
        });
    
        socket.on('groupOrderUpdated', (updatedOrder) => {
            // Check if the order is not a POS order
            if (!updatedOrder.isPOS) {
                if (notif.open) {
                    notif.open({
                        message: 'Order updated',
                        description: `Order ${updatedOrder.groupOrderNumber} has been updated.`,
                        type: 'success',
                    });
                }
            }
        });
    
        socket.on('groupOrderDeleted', (deletedOrder) => {
            // Check if the order is not a POS order
            if (!deletedOrder.isPOS) {
                if (notif.open) {
                    notif.open({
                        message: 'Order deleted',
                        description: `Order ${deletedOrder.groupOrderNumber} has been deleted.`,
                        type: 'progress',
                    });
                }
            }
        });
    
        return () => {
            socket.off('groupOrderCreated');
            socket.off('groupOrderUpdated');
            socket.off('groupOrderDeleted');
        };
    }, []);
    

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "orders",
                flex: 1,
                headerName: translate("orders.orders"),
                minWidth: 300,
                renderCell: function render({ row }) {
                    return  (<Typography sx={{ fontSize: "14px" }}>
                                 {row.orders.length} {translate("tags.item.plural")}
                             </Typography>)
                },
            },
            {
                field: "customer",
                flex: 1,
                headerName: translate("orders.fields.customer"),
                minWidth: 200,
                renderCell: function render({ value,  }) {
                    return <Typography sx={{ fontSize: "14px" }}>{value?.first_name + " " +value?.last_name}</Typography>;
                },
            },
            {
                field: "deliveryGuy",
                flex: 1,
                headerName: translate("orders.fields.deliveryGuy.deliveryGuy"),
                minWidth: 200,
                renderCell: function render({ value,  }) {
                    return <Typography sx={{ fontSize: "14px" }}>{value ? value?.first_name + " " +value?.last_name : ""}</Typography>;
                },
            },
            {
                field: "totalGroupedPrice",
                flex: 1,
                headerName: translate("orders.fields.totalOrderPrice"),
                type: "number",
                minWidth: 200,
            },
            {
                field: "status",
                headerName: translate("orders.fields.status"),
                headerAlign: "center",
                align: "center",
                renderCell: function render({ row }) {
                    return <OrderStatus status={row.status} />;
                },
                flex: 1,
                minWidth: 100,
            },
            {
                field: "createdAt",
                flex: 1,
                headerName: translate("orders.fields.createdAt"),
                minWidth: 250,
                renderCell: function render({ value }) {
                    // Use `format` from date-fns to format your date-time value
                    const formattedDate = format(new Date(value), "PPpp");
                    return <Typography sx={{ fontSize: "14px" }}>{formattedDate}</Typography>;
                },
            },
            {
                field: "updatedAt",
                flex: 1,
                headerName: translate("orders.fields.updatedAt"),
                minWidth: 250,
                renderCell: function render({ value }) {
                    const formattedDate = format(new Date(value), "PPpp");
                    return <Typography sx={{ fontSize: "14px" }}>{formattedDate}</Typography>;
                },
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row._id} />
                            <ShowButton hideText recordItemId={row._id} />
                            <DeleteButton hideText recordItemId={row._id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [translate,],
    );

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight getRowId={row => row._id}/>
        </List>
    );
};
