import React, { useEffect } from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    DeleteButton,
    List,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useTranslate, useNotification } from "@refinedev/core";
import { socket } from "App";
import newOrderSound from '../../sound/new_order_notif.mp3';

const orderCreatedSound = new Audio(newOrderSound);
export const AddressList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { dataGridProps } = useDataGrid();

    const notif = useNotification();

    useEffect(() => {
    socket.on('groupOrderCreated', (newOrder) => {
        // Check if the order is not a POS order
        if (!newOrder.isPOS) {
            if (notif.open) {
                notif.open({
                    message: 'New order received',
                    type: 'success',
                });
            }
            orderCreatedSound.play();
        }
    });

    socket.on('groupOrderUpdated', (updatedOrder) => {
        // Check if the order is not a POS order
        if (!updatedOrder.isPOS) {
            if (notif.open) {
                notif.open({
                    message: 'Order updated',
                    description: `Order ${updatedOrder.groupOrderNumber} has been updated.`,
                    type: 'success',
                });
            }
        }
    });

    socket.on('groupOrderDeleted', (deletedOrder) => {
        // Check if the order is not a POS order
        if (!deletedOrder.isPOS) {
            if (notif.open) {
                notif.open({
                    message: 'Order deleted',
                    description: `Order ${deletedOrder.groupOrderNumber} has been deleted.`,
                    type: 'progress',
                });
            }
        }
    });

    return () => {
        socket.off('groupOrderCreated');
        socket.off('groupOrderUpdated');
        socket.off('groupOrderDeleted');
    };
}, []);


    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "street",
                flex: 1,
                headerName: translate("addresses.fields.street"),
                minWidth: 200,
            },
            {
                field: "city",
                flex: 1,
                headerName: translate("addresses.fields.city"),
                minWidth: 200,
            },
            {
                field: "description",
                flex: 1,
                headerName: translate("addresses.fields.description"),
                minWidth: 200,
            },
            {
                field: "belongsTo",
                flex: 1,
                headerName: translate("addresses.fields.belongsTo"),
                minWidth: 200,
            },
            {
                field: "belongsToModel",
                flex: 1,
                headerName: translate("addresses.fields.belongsToModel"),
                minWidth: 200,
            },
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row._id} />
                            <ShowButton hideText recordItemId={row._id} />
                            <DeleteButton hideText recordItemId={row._id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [translate],
    );

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight getRowId={row => row._id}/>
        </List>
    );
};
